import base64 from 'base64-js'
import { getAdsPlatformName, getOSVersion } from '@dstv-web-leanback/dstv-frontend-services'

export const getPulseAdsConfig = (ssaiEnabled, type, pid, userPackage, adRequest) => {
	const isLinearScheduling = ssaiEnabled?.payload?.scheduling === 'Linear'
	const adOptions = isLinearScheduling ? ssaiEnabled.payload.status ?? '' : ''

	const tagInfo = `PA:${userPackage}${adOptions ? `,AO:${adOptions}` : ''}`

	let tags = tagInfo

	if (adRequest) {
		let tagsInfoAd = `${adRequest?.t.join(',')},${tagInfo}`.replace(/\s+/g, '')
		tags = tagsInfoAd
	}
	const platform = getAdsPlatformName()
	const userAgentVersion = getOSVersion()

	const baseConfig = {
		pid: pid,
		dcid: platform,
		t: tags,
		pf: 'html5_' + userAgentVersion,
	}

	if (ssaiEnabled.flagValue === 'true' && type === 'live') {
		return preparePulseAdParameters({
			...baseConfig,
			gdpr: 0,
			gdpr_consent: 0,
			gdpr_pd: 0,
		})
	}

	return preparePulseAdParameters({
		...baseConfig,
		acp: {
			package: userPackage,
		},
	})
}

export const preparePulseAdParameters = (pulseAdsConfig) => {
	let queryParam = ''

	Object.keys(pulseAdsConfig).forEach((key) => {
		if (
			typeof pulseAdsConfig[key] === 'object' &&
			pulseAdsConfig[key] !== null &&
			Object.keys(pulseAdsConfig[key]).length > 0
		) {
			Object.keys(pulseAdsConfig[key]).forEach((j) => {
				queryParam += (!queryParam ? '' : '&') + (key + '.' + j) + '=' + pulseAdsConfig[key][j]
			})
		} else {
			queryParam += (!queryParam ? '' : '&') + key + '=' + pulseAdsConfig[key]
		}
	})

	const textEncoder = new TextEncoder()
	const bytes = textEncoder.encode(queryParam)
	const encodedString = base64.fromByteArray(bytes)

	return encodedString
}
export const getPlayerUrl = (playerState) => {
	return playerState.watchFromStartLive
		? playerState.timeShiftUrl
			? playerState.timeShiftUrl
			: playerState.details.timeShiftUrl
		: playerState.url
}

export const isTimeShift = (playerState) => {
	return !playerState.watchFromStartLive && !playerState.backToLive
}

const prepareConfigObject = (originalConfig) => {
	let parsedConfig = {}

	for (let key in originalConfig) {
		try {
			parsedConfig[key] = originalConfig[key].includes('.')
				? parseFloat(originalConfig[key])
				: parseInt(originalConfig[key])
		} catch (err) {
			parsedConfig[key] = null
		}
	}

	return parsedConfig
}

export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href
	name = name.replace(/[[]]/g, '\\$&')
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url)
	if (!results) return null
	if (!results[2]) return ''
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
