import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	DialogFull,
	LiveControls,
	Loader,
	PulsePlayer,
	useRefState,
	VODControls,
	ShakaPlayer,
	getPlayerConfiguration,
	getStreamUrl,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	changeBandwidth,
	CLEAR_AUDIO_LANGUAGE_AND_BANDWIDTH,
	GET_BOOKMARK,
	GET_CDN_TOKEN,
	getAudioLanguages,
	getUserID,
	getVideoId,
	IS_PLAYER_READY,
	PAUSE,
	PLAY,
	PLAYER_ERROR,
	RESET_CDN_TOKEN,
	RESUME_PLAY,
	SAVE_BOOKMARK,
	SEEK,
	selectAudioLanguage,
	SWITCH_CHANNEL,
	useBitmovinAnalytics,
	useEntitlementRefresh,
	useNavigation,
	usePermutive,
	CLEAR_CONTENT_DISCOVERY_REFERRER,
	AD_SESSION,
	BACKTO_LIVE,
	HAS_USER_PAUSED,
	PLAYER_READY,
	WATCH_FROM_START_LIVE,
} from '@dstv-web-leanback/dstv-frontend-services'

import { getPulseAdsConfig, getPlayerUrl, isTimeShift, getParameterByName } from './helpers'
import { getErrorDetails } from '../../utils/helper'
import styles from './Player.module.scss'

export function Player() {
	const shakaPlayer = useRef(null)
	const adPlayerRef = useRef(null)
	const nextEpisodeRef = useRef(null)
	const { player, videoElement } = shakaPlayer.current || {}

	const autoplayState = useSelector((state) => state.autoplay)
	const cdnAuthState = useSelector((state) => state.cdn_auth)
	const channelsState = useSelector((state) => state.channels)
	const playerState = useSelector((state) => state.player)
	const adPlayerState = useSelector((state) => state.adPlayer)
	const userState = useSelector((state) => state.user)
	const profileState = useSelector((state) => state.profile)
	const configState = useSelector((state) => state.config.data)
	const settingState = useSelector((state) => state.settings)

	const [loading, setLoading] = useState(true)
	const [buffering, setBuffering] = useState(true)
	const [playing, setPlaying] = useState(false)
	const [currentTime, setCurrentTime, currentTimeRef] = useRefState()
	const [liveSeekTime, setLiveSeekTime] = useState(0)

	const { bitmovinConfig, initAnalytics, updateConfig } = useBitmovinAnalytics()

	const dispatch = useDispatch()
	const { navigateBack } = useNavigation()

	const { handleVideoPlayback } = usePermutive(getUserID())
	const { entitlementState, loadEntitlement } = useEntitlementRefresh(player, () => {
		onLicenseRequest()
	})

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				navigateBack()
			}
		}

		document.addEventListener('visibilitychange', handleVisibilityChange)

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [navigateBack])

	// initiate data to start playback
	useEffect(() => {
		if (playerState.details) {
			handleVideoPlayback(playerState.details, playerState.trackingPlayId)
			initPlayer()

			return () => {
				if (playerState.type === 'vod') {
					saveBookmark()
					dispatch(CLEAR_CONTENT_DISCOVERY_REFERRER())
				}
				dispatch(RESET_CDN_TOKEN())
			}
		}
	}, [playerState.details])

	// start bitmovin analytics
	useEffect(() => {
		if (player) {
			let isLive = playerState.type === 'live'
			let videoID = isLive ? playerState?.details?.channelUuid : playerState?.details?.genref || 'Video ID not found'
			let videoTitle = playerState?.details?.title || 'No title Found'
			let cfg = {
				...bitmovinConfig,
				isLive,
				videoId: videoID,
				title: videoTitle,
				customData1: playerState.trackingPlayId,
				customData3: 'VERSION_NAME: ' + APP_VERSION,
				customData4: 'dstv',
				customData5: configState?.shaka_abr_config?.flagValue ?? 'null',
				customData6: configState?.shaka_retry_config?.flagValue ?? 'null',
				customData8:
					configState?.enable_cv_player?.payload?.variant !== null
						? 'Variant' + configState?.enable_cv_player?.payload?.variant
						: 'null',
			}

			initAnalytics(cfg, player)

			return () => {
				updateConfig({ ...bitmovinConfig, player: null, videoId: null, title: '' })
			}
		}
	}, [player, playerState.type, playerState.details])

	// If there is a bookmark, seek to it
	useEffect(() => {
		dispatch(IS_PLAYER_READY(true))
		if (!playerState.ignoreBookmarks) {
			seekTo(playerState.bookmark)
		}
	}, [playerState.bookmark])

	// Set next Episode data
	useEffect(() => {
		if (autoplayState.data) {
			nextEpisodeRef.current = autoplayState.data
		}
	}, [autoplayState.data])

	const [url, config] = useMemo(() => {
		let playerPackage = null
		let url = null
		let config = null

		if (entitlementState.error) {
			if (adPlayerRef) adPlayerRef?.current?.error()
		}

		const loadingEntitlement = entitlementState.data ? false : entitlementState.loading

		if (!entitlementState.data?.irdetoSession?.sessionId || !entitlementState.data?.ucp_filter) {
			return [url, config]
		}

		if (playerState.type === 'live') {
			let contentId = getParameterByName('contentId', playerState.url)

			if (!contentId) {
				contentId = playerState.details.channelTag
			}

			if (loadingEntitlement === false && cdnAuthState.loading === false) {
				if (cdnAuthState.accessToken && cdnAuthState.channelTag === playerState.details?.channelTag) {
					playerPackage = {
						type: 'live',
						url: playerState.url,
						contentId: contentId,
						sessionId: entitlementState.data.irdetoSession.sessionId,
						ucp_filter: entitlementState.data.ucp_filter,
						cdnAuthToken: cdnAuthState.accessToken,
					}
				}
			}
		} else if (playerState.type === 'vod') {
			if (loadingEntitlement === false) {
				playerPackage = {
					type: 'vod',
					contentId: playerState.details.contentId,
					url: playerState.url,
					sessionId: entitlementState.data.irdetoSession?.sessionId,
					ucp_filter: entitlementState.data.ucp_filter,
				}
			}
		}

		if (playerPackage) {
			const queryParam = getPulseAdsConfig(
				configState?.ssai_enabled,
				playerPackage.type,
				getUserID(),
				userState.userPackage,
				playerState.details.adRequest
			)

			config = getPlayerConfiguration(playerPackage.sessionId, playerPackage.type, playerPackage, configState)
			const playerUrl = getPlayerUrl(playerState)
			url = getStreamUrl(
				playerPackage.type,
				playerUrl,
				playerPackage.ucp_filter,
				playerPackage.cdnAuthToken,
				queryParam,
				playerState.watchFromStartLive,
				configState
			)
		}

		return [url, config]
	}, [
		cdnAuthState.loading,
		cdnAuthState.accessToken,
		entitlementState.loading,
		entitlementState.data,
		playerState.type,
		playerState.details?.channelTag,
		playerState.details?.contentId,
		playerState.url,
	])

	const initPlayer = (forceRefresh) => {
		setLoading(true)
		if (forceRefresh) {
			loadEntitlement()
		}

		if (playerState.type === 'live') {
			dispatch(GET_CDN_TOKEN({ channelTag: playerState?.details?.channelTag }))
		} else {
			dispatch(GET_BOOKMARK({ id: playerState?.details?.genref }))
		}
	}

	const handleAssetLoaded = () => {
		if (isTimeShift(playerState)) dispatch(CLEAR_AUDIO_LANGUAGE_AND_BANDWIDTH())
		setLoading(false)
		dispatch(PLAYER_READY())
		if (playerState.watchFromStartLive) {
			setLiveSeekTime(0)
			videoElement.currentTime = player.seekRange().start
		} else setLiveSeekTime(getLiveSeekRange())
		play()
	}

	const playPause = () => {
		if (videoElement.paused) {
			dispatch(HAS_USER_PAUSED(false))
			play()
		} else {
			dispatch(HAS_USER_PAUSED(true))
			pause()
		}
	}

	const play = () => {
		videoElement.play()
		setPlaying(true)
		dispatch(RESUME_PLAY({ isPlaying: true, currentPosition: currentTimeRef.current }))
	}

	const pause = () => {
		videoElement.pause()
		setPlaying(false)
		dispatch(PAUSE({ isPlaying: false, currentPosition: currentTimeRef.current }))
	}

	const seekTo = (time = 0) => {
		if (!videoElement) {
			return
		}

		const currentPosition = videoElement.currentTime
		let newPosition = 0

		if (time !== null && time !== undefined) {
			newPosition = time
		}
		if (playerState.type === 'live') {
			const seekedPosition =
				liveSeekTime + newPosition < 0
					? 0
					: liveSeekTime + newPosition > getLiveSeekRange()
					? getLiveSeekRange()
					: liveSeekTime + newPosition
			setLiveSeekTime(seekedPosition)
			newPosition =
				getLiveSeekRange() === 0 ? newPosition : player.seekRange().end - (getLiveSeekRange() - seekedPosition)
		}

		videoElement.currentTime = newPosition

		dispatch(SEEK({ previousPosition: currentPosition, newPosition: videoElement.currentTime }))
	}

	const saveBookmark = (time) => {
		const timeInSeconds = time || currentTimeRef.current
		if (timeInSeconds) {
			dispatch(
				SAVE_BOOKMARK({
					genref: playerState?.details?.genref?.split('_')[1] || playerState?.details?.genref,
					timeInSeconds: time || currentTimeRef.current,
				})
			)
		}
	}

	const handleTimeUpdate = (event) => {
		let newSecond = Math.floor(event.target.currentTime)
		if (newSecond % 1 === 0 && currentTimeRef.current !== newSecond) {
			setCurrentTime(newSecond)
		}
	}

	const onVideoEnded = (event) => {
		if (adPlayerState.adSession?.state === 'start') return
		endVideo()
	}

	const onVideoEndedFromPulse = () => {
		endVideo()
	}

	const endVideo = () => {
		saveBookmark()
		if (settingState.autoplay_enabled && configState.autoplay_enabled?.flagValue === 'true' && !!autoplayState.data) {
			playNextEpisode()
		} else {
			navigateBack()
		}
	}

	const playNextEpisode = () => {
		setLoading(true)
		setPlaying(false)

		dispatch(
			PLAY({
				url: nextEpisodeRef.current?.stream_url,
				ignoreBookmarks: true,
				details: {
					genref: getVideoId(nextEpisodeRef.current),
					contentId: nextEpisodeRef.current?.stream_url?.split('/')?.reverse()?.[1],
					...nextEpisodeRef.current,
				},
				type: 'vod',
			})
		)

		dispatch(AD_SESSION({ state: 'autoplay', adRequest: nextEpisodeRef.current.ad_request }))
	}

	const switchChannel = (channelTag) => {
		if (!channelTag) return

		dispatch(CLEAR_AUDIO_LANGUAGE_AND_BANDWIDTH())

		setLoading(true)
		setPlaying(false)
		let targetChannel = channelsState.all_channels.find((channel) => channel.tag === channelTag)
		let targetProgram = targetChannel?.events?.[0] || ''
		dispatch(
			SWITCH_CHANNEL({
				url: targetChannel.playerUrl,
				details: {
					program: targetProgram,
					channel: targetChannel,
				},
				type: 'live',
			})
		)

		dispatch(
			PLAY({
				url: targetChannel.playerUrl,
				details: {
					...targetProgram,
					channelTag,
					channelNumber: targetChannel.number,
					timeShiftUrl: targetProgram?.timeShiftStream?.dash,
				},
				type: 'live',
			})
		)
	}

	const onError = (error) => {
		if (error?.detail) {
			adPlayerRef && adPlayerRef?.current?.error()
		}
		dispatch(PLAYER_ERROR({ details: error }))
	}

	const onBuffer = (event) => {
		setBuffering(event.buffering)
	}

	const onLicenseRequest = () => {
		if (playerState.type === 'vod') {
			saveBookmark()
		}
	}

	const handleAudioChange = (audioLanguage) => {
		selectAudioLanguage(player, audioLanguage)
	}

	const handleBandwidthChange = (bandwidth) => {
		changeBandwidth(player, bandwidth)
	}

	const handleSubOptionChange = (optionHeading, subOption) => {
		if (optionHeading === 'Streaming Quality') {
			handleBandwidthChange(subOption.value)
		} else if (optionHeading === 'Audio Language') {
			handleAudioChange(subOption.language)
		}
	}
	const getLiveSeekRange = () => {
		return player?.seekRange ? player.seekRange().end - player.seekRange().start : 0
	}
	const handleBackToLive = () => {
		setLoading(true)
		setPlaying(false)

		dispatch(
			BACKTO_LIVE({
				url: playerState.url,
				details: { ...playerState.details },
				buttonText: 'back to live',
				type: 'live',
			})
		)
	}
	const dvrWatchfromStart = () => {
		setLoading(true)
		setPlaying(false)
		dispatch(
			WATCH_FROM_START_LIVE({
				url: playerState.url,
				details: { ...playerState.details },
				type: 'live',
				buttonText: 'watch from start',
			})
		)
	}

	if (playerState.error ?? entitlementState.error ?? cdnAuthState.error) {
		return (
			<DialogFull
				{...getErrorDetails([playerState, entitlementState, cdnAuthState])}
				backAction={() => {
					navigateBack()
				}}
				retryAction={() => {
					if (
						getErrorDetails([playerState, entitlementState, cdnAuthState]).error.mainHeading ===
						'No internet connection'
					) {
						navigateBack()
					} else {
						setPlaying(false)
						initPlayer(true)
					}
				}}
			/>
		)
	}

	return (
		<div className={styles.player_container}>
			{loading || !player ? (
				<Loader focusable={true} />
			) : (
				<>
					{playerState.type === 'vod' && (
						<VODControls
							audioLanguages={getAudioLanguages(player)}
							handleAudioChange={handleAudioChange}
							handleBandwidthChange={handleBandwidthChange}
							handleSubOptionChange={handleSubOptionChange}
							currentTime={currentTime}
							video={videoElement}
							videoDetails={playerState.details}
							playing={playing}
							playNextEpisode={playNextEpisode}
							playPause={playPause}
							play={play}
							pause={pause}
							seekTo={seekTo}
						/>
					)}
					{playerState.type === 'live' && (
						<LiveControls
							audioLanguages={getAudioLanguages(player)}
							handleAudioChange={handleAudioChange}
							handleBandwidthChange={handleBandwidthChange}
							handleSubOptionChange={handleSubOptionChange}
							playing={playing}
							playPause={playPause}
							switchChannel={switchChannel}
							seekRange={getLiveSeekRange}
							liveSeekTime={liveSeekTime}
							seekTo={seekTo}
							handleBackToLive={handleBackToLive}
							currentTime={currentTime}
							liveSeekTimeEnd={Math.floor(player?.seekRange && player.seekRange().end)}
							playbackCapabilities={userState.playbackcapabilities}
							dvrWatchfromStart={dvrWatchfromStart}
						/>
					)}
				</>
			)}
			{buffering && <Loader overlay={false} />}
			<ShakaPlayer
				ref={shakaPlayer}
				url={url}
				config={config}
				onEnded={onVideoEnded}
				onTimeUpdate={handleTimeUpdate}
				onLoaded={handleAssetLoaded}
				onError={onError}
				onBuffer={onBuffer}
			/>

			<PulsePlayer ref={adPlayerRef} content={videoElement} onEnded={onVideoEndedFromPulse} />
		</div>
	)
}

export default Player
